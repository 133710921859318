import React, { Component } from 'react';
import MonthNavigation from './MonthNavigation.js';
import { API_ROOT } from './config.js';

class FinancesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incomes: [],
            expenses: [],
            credit_payments: [],
            savings_transfers: [],
            income_total: 0,
            expense_total: 0,
            date: new Date().getDate(),
            month: new Date(),
            nav_date: new Date(),
            amount: 0.00,
            category: "",
            name: "",
            type: "cash",
            income_categories: {
                "other": 0
            },
            expense_categories: {
                "rent/parking": 0,
                "utilities": 0,
                "car payment": 0,
                "car insurance": 0,
                "gas/transportation": 0,
                "food out": 0,
                "alcohol": 0,
                "groceries": 0,
                "subscriptions": 0,
                "personal care": 0,
                "gym": 0,
                "entertainment": 0,
                "discretionary": 0,
                "other": 0
            },

        }
        this.getTransactions();

    }

    months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

    expense_budget_categories = {
        "rent/parking": 1367.95,
        "utilities": 213.00,
        "car payment": 400,
        "car insurance": 200,
        "gas/transportation": 150,
        "food out": 200,
        "alcohol": 150,
        "groceries": 300,
        "subscriptions": 61,
        "personal care": 120,
        "gym": 60,
        "entertainment": 200,
        "discretionary": 300,
        "other": 0
    }

    setDate = (date) => {
        this.setState({ nav_date: date }, () => {
            this.getTransactions();
        });
    }

    logOut = () => {
        localStorage.removeItem('token');
        window.location.reload();
    }

    compareByDate = (a, b) => {
        var date1 = new Date(a['date']);
        var date2 = new Date(b['date']);

        if (date1 < date2) return -1;
        if (date1 === date2) return 0;
        return 1;

    } 

    

    sortAndSaveData = (data) => {

        var incomes = []
        var expenses = []
        var credit_payments = []
        var savings_transfers = []
        var income_total = 0
        var expense_total = 0

        var income_categories = {
            "other": 0
        }
        
        var expense_categories = {
            "rent/parking": 0,
            "utilities": 0,
            "car payment": 0,
            "car insurance": 0,
            "gas/transportation": 0,
            "food out": 0,
            "alcohol": 0,
            "groceries": 0,
            "subscriptions": 0,
            "personal care": 0,
            "gym": 0,
            "entertainment": 0,
            "discretionary": 0,
            "other": 0
        }

        data.map((transaction) => {
            if (transaction['type'] === 'income') {
                income_total += transaction['amount'];
                incomes.push(transaction);

                if (transaction['finances_category']) {

                    if (income_categories[transaction['finances_category']]){
                        income_categories[transaction['finances_category']] = income_categories[transaction['finances_category']] + transaction['amount']
                    }
                    else {
                        income_categories[transaction['finances_category']] = transaction['amount']
                    }
                }
                else {
                    income_categories["other"] += transaction['amount']
                }
            }
            else if (transaction['type'] === "debit card expense" || transaction['type'] === 'credit card expense' || transaction['type'] === 'cash') {
                expense_total += transaction['amount'];
                expenses.push(transaction);

                if (transaction['finances_category']) {

                    if (expense_categories[transaction['finances_category']]){
                        expense_categories[transaction['finances_category']] = expense_categories[transaction['finances_category']] + transaction['amount']
                    }
                    else {
                        expense_categories[transaction['finances_category']] = transaction['amount']
                    }
                }
                else {
                    expense_categories["other"] += transaction['amount']
                }
            }
            else if (transaction['type'] === 'credit card payment') {
                credit_payments.push(transaction);
            }
            else {
                savings_transfers.push(transaction);
            }
            return 0;
        })

        this.setState(
            {
                incomes: incomes.sort(this.compareByDate),
                expenses: expenses.sort(this.compareByDate),
                savings_transfers: savings_transfers.sort(this.compareByDate),
                credit_payments: credit_payments.sort(this.compareByDate),
                income_total: Math.round(income_total * 100) / 100,
                expense_total: Math.round(expense_total * 100) / 100,
                income_categories: income_categories,
                expense_categories: expense_categories,
            }
        )
    }

    getTransactions = () => {

		var data = {
			method: "GET",
			headers: {
                'Content-Type': 'application/json',
                'x-api-key': this.props.token
			},
			mode: 'cors',
        };
        
        var query = '?month=' + (this.state.nav_date.getMonth() + 1) + '&year=' + (this.state.nav_date.getFullYear());

        fetch(API_ROOT + '/transactions/' + query, data)
            .then((data) => { 
                data.json()
                    .then((data) => { 
                        this.sortAndSaveData(data);
                    }) 
            })
            .catch(err => { alert(err); });

    }

    handleChange = (attribute) => {
		return (event) => {
			var obj = {};
			obj[attribute] = event.target.value;
			this.setState(obj);
		}
    }
    
    submit = () => {
        var date_str = '' + new Date().getFullYear() + '-'
        var day;
        var month = this.state.nav_date.getMonth() + 1;
        var month_str = ''

        if (month < 10)
        {
            month_str = '0' + month + '-';
        }
        else {
            month_str = '' + month + '-';
        }

        if (('' + this.state.date).length < 2) {
            day = '0' + this.state.date;
        }
        else {
            day = this.state.date;
        }

        date_str += month_str
        date_str += day;

        var transaction = {
            amount: this.state.amount,
            date: date_str,
            type: this.state.type,
            name: this.state.name,
            finances_category: this.state.category
        }

        var data = {
			method: "POST",
			headers: {
                'Content-Type': 'application/json',
                'x-api-key': this.props.token
			},
            mode: 'cors',
            body: JSON.stringify(transaction)
        };

        fetch(API_ROOT + '/transactions/', data)
            .then((data) => { 
                data.json()
                    .then(() => { 
                        this.setState({
                            date: new Date().getDate(),
                            amount: 0.00,
                            name: "",
                        });
                        this.getTransactions();
                    }) 
            })
            .catch(err => { console.log(err); }); 
    }

    handleUpdate = (transaction) => {
        return (event) => {
            transaction.finances_category = event.target.value;
            
            var data = {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.props.token
                },
                mode: 'cors',
                body: JSON.stringify(transaction)
            };
            
            fetch(API_ROOT + '/transactions/', data)
                .then((data) => { 
                    data.json()
                        .then(() => { 
                            this.getTransactions();
                        }) 
                })
                .catch(err => { console.log(err); }); 

        }
    }

    handleDelete = (transaction) => {
        return () => {

            var data = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': this.props.token
                },
                mode: 'cors',
                body: JSON.stringify({transaction_id: transaction.transaction_id, date: transaction.date})
            };
            
            fetch(API_ROOT + '/transactions/', data)
                .then(() => { 
                    this.getTransactions();
                }) 
                .catch(err => { console.log(err); }); 
        }
    }

    renderCategories = (dict, total) => {

        var cats = Object.keys(dict).map((key) => {

            var color = 'black';
            if (dict[key] > this.expense_budget_categories[key]) {
                color = 'red';
            }
            else if (this.expense_budget_categories[key] > dict[key]) {
                color = 'green';
            }
            
            return (
                <tr key={ key } >
                    <td>
                        { key }
                    </td>
                    <td style={{ color:color }}>
                        { dict[key] }
                    </td>
                    <td>
                        { this.expense_budget_categories[key] }
                    </td>
                </tr>
            )
        })

        cats.push(  
            <tr key={"total"} >
                <td>
                    Total
                </td>
                <td>
                    { total }
                </td>
                <td>
                    3801.95
                </td>
            </tr>
        )

        return cats;
    }

    render() {

        return (
            <div>
                <button onClick={this.logOut}>Log Out</button>
                <h2>New Transaction</h2>
                <label>
                    Day:
                    <input type='number' value={this.state.date} onChange={this.handleChange('date')}></input>
                </label>
                <label>
                    Amount:
                    <input type='number' value={this.state.amount} onChange={this.handleChange('amount')}></input>
                </label>
                <label>
                    Name:
                    <input type='text' value={this.state.name} onChange={this.handleChange('name')}></input>
                </label>
                <label>
                    Type:
                    <select value={this.state.type} onChange={this.handleChange('type')}>
                        <option value="cash expense">Cash</option>
                        <option value="credit card expense">Credit</option>
                        <option value="debit card expense">Debit</option>
                        <option value="income">Income</option>
                    </select>
                </label>
                <label>
                    Category:
                    <select value={this.state.category} onChange={this.handleChange('category')}>
                    { this.state.type === 'income' ? 
                        (
                            <>
                            <option value="other">Other</option>
                            <option value="primary income">Primary Income</option>
                            <option value="tax return">Tax Return</option>
                            <option value="bonus income">Bonus Income</option>
                            </>
                        ) : (
                            <>
                            <option value="other">Other</option>
                            <option value="rent/parking">Rent/Parking</option>
                            <option value="utilities">Utilities</option>
                            <option value="car payment">Car Payment</option>
                            <option value="car insurance">Car Insurance</option>
                            <option value="gas/transportation">Gas/Transportation</option>
                            <option value="food out">Food Out</option>
                            <option value="alcohol">Alcohol</option>
                            <option value="groceries">Groceries</option>
                            <option value="subscriptions">Subscriptions</option>
                            <option value="personal care">Personal Care</option>
                            <option value="phone bill">Phone Bill</option>
                            <option value="gym">Gym</option>
                            <option value="entertainment">Entertainment</option>
                            <option value="discretionary">Discretionary</option>
                            </>
                        )
                    }
                    </select>
                </label>
                <button onClick={this.submit}>Submit</button>
                <h1>Totals: {this.months[this.state.nav_date.getMonth()]}, {this.state.nav_date.getFullYear()}</h1>

                <table>
                    <thead>
                        <tr>
                            <th>Income</th>
                            <th>Expenses</th>
                            <th>Net</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {this.state.income_total}
                            </td>
                            <td>
                                {this.state.expense_total}
                            </td>
                            <td>
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <br/>
                
                <table>
                    <thead>
                        <tr>
                            <th>
                                Categories
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderCategories(this.state.expense_categories, this.state.expense_total) }
                    </tbody>
                </table>

                <h1>Incomes</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.incomes.map((income) => 
                            
                            (<tr key={income['transaction_id']}>
                                <td>{income['date']}</td>
                                <td>{income['amount']}</td>
                                <td>{income['name']}</td>
                                <td>
                                    <label>
                                        <select value={income.finances_category} onChange={this.handleUpdate(income)}>
                                            <option value="other">Other</option>
                                            <option value="primary income">Primary Income</option>
                                            <option value="tax return">Tax Return</option>
                                            <option value="bonus income">Bonus Income</option>
                                        </select>
                                    </label>
                                </td>
                                <td onClick={this.handleDelete(income)} style={{ cursor: 'pointer' }}>X</td>
                            </tr>)
                        )}
                    </tbody>
                </table>
                <h1>Expenses</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.expenses.map((expense) => 

                            (<tr key={expense['transaction_id']}>
                                <td>{expense['date']}</td>
                                <td>{expense['amount']}</td>
                                <td>{expense['name']}</td>
                                <td>
                                    <label>
                                        <select value={expense.finances_category} onChange={this.handleUpdate(expense)}>
                                            <option value="other">Other</option>
                                            <option value="rent/parking">Rent/Parking</option>
                                            <option value="utilities">Utilities</option>
                                            <option value="car payment">Car Payment</option>
                                            <option value="car insurance">Car Insurance</option>
                                            <option value="gas/transportation">Gas/Transportation</option>
                                            <option value="food out">Food Out</option>
                                            <option value="alcohol">Alcohol</option>
                                            <option value="groceries">Groceries</option>
                                            <option value="subscriptions">Subscriptions</option>
                                            <option value="personal care">Personal Care</option>
                                            <option value="phone bill">Phone Bill</option>
                                            <option value="gym">Gym</option>
                                            <option value="entertainment">Entertainment</option>
                                            <option value="discretionary">Discretionary</option>
                                        </select>
                                    </label>
                                </td>
                                <td onClick={this.handleDelete(expense)} style={{ cursor: 'pointer' }}>X</td>
                            </tr>)
                        )}
                    </tbody>
                </table>
                <MonthNavigation setDate={this.setDate} selected_date={this.state.nav_date}/>
            </div>
        );
    }
}

export default FinancesView;
